import React from "react";
import { Link } from 'react-router-dom';
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css"
import '../slide.css';
export const SlideShow = () => {

  return (
    <div>
      <div>
        <Slide autoplay={false} arrows={false}>
          <div className="bg-img1" style={{ backgroundImage : 'url(img/home-slider/slider-1.jpg)'}}>
                <div className="bannerContent">
                <div className="contentTitle">
                    Berry Awesome ... <br/>  make delicious products.
                </div>
                <p className="contentSubText">
                Satisfy your cravings and treat your taste buds to some delicious delights.
                </p>
                <Link to="/shop">
                <button className="btn btn-default berry-btn">View Our Deserts</button>
                </Link>
                </div>
          </div>

          <div className="bg-img2" style={{ backgroundImage : 'url(background2.png)' }}>
          <div className="bannerContent">
                <div className="contentTitle">
                    Berry Awesome ... <br/>  make delicious products.
                </div>
                <p className="contentSubText">
                Satisfy your cravings and treat your taste buds to some delicious delights.
                </p>
                <Link to="/shop">
                <button className="btn btn-default berry-btn">View Our Deserts</button>
                </Link>
                </div>
          </div>
         
        </Slide>
      </div>
    </div>
  );
};