import { makeAutoObservable } from 'mobx';
import fetch from 'node-fetch';

const ENDPOINTS = {
    catalog: `/berryaws/catalog`,
    catalogList: `/berryaws/catalog/list`,
    checkout: `/berryaws/checkout`,
    contact: `/berryaws/contact`
}

const TOP_SELLERS = [
    '362TJOBUKENRSQKQ4KUDSV4D',
    'IFFGCQ2ZQYJTPIEAJZCZDO3L',
    'QS4VZQLKD3YL7GVR2C5A5O4R',
    'UNHG32UOYE3ROABT6FKGBVCB'
];

const LOCATION_ID = "LEAN9PSN0D1S4";
export class BerryAwesomeStore {
    loading = true;
    emailMessage = "";
    categories = new Map();
    categoryItemCountMap = new Map();
    storeItems = new Map();
    shopCartItems = new Map();
    imageItems = new Map();
    unitMap = new Map();

    topSellers = TOP_SELLERS;

    selectedCategory = '';
    taxPercent = 8.25 / 100;
    taxDisplay = "8.25";
    taxName = "Sales Tax"
    taxDeliveryPercent = 3 / 100;
    taxDisplayDelivery = "3.0"
    taxNameDelivery = "Delivery Fee"

    cartInfo = {
        subTotal: 0,
        total: 0,
        salesTax: 0,
        deliveryFee: 0
    }

    storeError = true;

    
    constructor() {
        makeAutoObservable(this);

        setTimeout(() => {
            this.loadStore();
        }, 5000);
    }


    loadStore = async () => {
        this.loading = true;
        try {
            let response = await fetch(ENDPOINTS.catalog).then( r => r.json());
            let response2 = await fetch(ENDPOINTS.catalogList).then( r => r.json());

            let items = [...response.objects, ...response2.objects];

            items.forEach( item => {
                if(item.type === "CATEGORY"){
                    this.categories.set(item.id, item);
                    this.categoryItemCountMap.set(item.id, 0);
                    return;
                }

                if(item.type === "ITEM"){
                    this.storeItems.set(item.id, item);
                    return;
                }

                if(item.type === "IMAGE"){
                    this.imageItems.set(item.id, item);
                    return
                }

                if(item.type === "MEASUREMENT_UNIT") {
                    this.unitMap.set(item.id, item?.measurement_unit_data?.measurement_unit?.custom_unit || {
                        name: "Dozen",
                        abbreviation: "1dz"
                    });
                    return;
                }

                if(item.type === "TAX" && item.tax_data.name.includes("Sales")) {
                    this.taxPercent = parseFloat(item.tax_data.percentage) / 100;
                    this.taxDisplay = item.tax_data.percentage;
                    this.taxName = item.tax_data.name;
                    return;
                }

                if(item.type === "TAX" && item.tax_data.name.includes("Fee")) {
                    this.taxDeliveryPercent = parseFloat(item.tax_data.percentage) / 100;
                    this.taxDisplayDelivery = item.tax_data.percentage;
                    this.taxNameDelivery = item.tax_data.name;
                }
            });

            this.storeItems.forEach( item => {
                let catId = item?.item_data?.category_id;

                if(this.categories.has(catId)){
                    this.categoryItemCountMap.set(catId, this.categoryItemCountMap.get(catId) + 1);
                }
            });

        } catch(e) {
            this.storeError = true;
        } finally {
            this.loading = false;
            this.selectedCategory = "2SITNAKYOCN7YSF2PZI3AWPP";
        }
    }

    setViewDetails = (itemId = null) => {
            if(itemId) {
                this.viewItemDetailsId = itemId;
            } else {
                this.viewItemDetailsId = "";
            }
    }

    selectCategory = id => {
        this.selectedCategory = id;
    }
    addItemToCart = (item) => { 
        this.shopCartItems.set(item.id, { ...item, qty: item?.qty || 1 });
        this.updateCost();
    }

    removeItemFromCart = (itemId) => {
        this.shopCartItems.delete(itemId);
        this.updateCost();
    }

    updateCost = () => {
        this.cartInfo.subTotal = 0;
        this.cartInfo.total = 0;

        this.shopCartItems.forEach( item => {
            let itemPrice = item?.item_data?.variations[0].item_variation_data?.price_money?.amount;
            if(itemPrice) {
                itemPrice = itemPrice / 100;
            }

            this.cartInfo.subTotal += itemPrice * item.qty;
        });

        this.cartInfo.deliveryFee = this.cartInfo.subTotal * this.taxDeliveryPercent;
        this.cartInfo.salesTax = this.cartInfo.subTotal * this.taxPercent;
        this.cartInfo.total = (this.cartInfo.subTotal + (this.cartInfo.subTotal * this.taxPercent)) + this.cartInfo.deliveryFee;

    }

    checkout = async () => {
        // Checkout location_id if any errors
        let order = {
            idempotency_key: this.uid4(),
            location_id: LOCATION_ID,
            order: {
                order: {
                    idempotency_key: this.uid4(),
                    reference_id: `order-${Date.now()}`,
                    location_id: LOCATION_ID,
                    line_items: [...this.shopCartItems.values()].map( item => {
                      return {
                          name: item.item_data.name,
                          quantity: String(item.qty),
                          base_price_money: 
                          {
                             amount: item.item_data.variations[0].item_variation_data.price_money.amount,
                             currency: "USD"
                           }
                      }
                    }),
                   taxes:
                   [{
                      name: this.taxName,
                      percentage: this.taxDisplay
                   },
                    {
                    name: this.taxNameDelivery,
                    percentage: this.taxDisplayDelivery
                    }]
                 }
            },
            ask_for_shipping_address: true,
            merchant_support_email: "berryawesome2018@gmail.com",
            redirect_url: "https://www.berryawesometreats.com/thankyou"
     }

     try {
        let checkOutResponse = await fetch(ENDPOINTS.checkout, { 
            method: "post", 
            body: JSON.stringify(order),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(
            r => r.json()
        );

        if(checkOutResponse.checkout) {
            window.location = checkOutResponse.checkout.checkout_page_url;
        }else{
            throw new Error('checkout failed');
        }
     } catch(e) {
        window.alert("Out of service temporarily. Please check back again later.");
        console.log(e.message);
     }
    }

    sendEmail = async (contactInfo) => {
        for(const key in contactInfo) {
            if(!String(contactInfo[key].trim())){
                window.alert('Please provide missing fields');
                return;
            }
        }

        try {
            await fetch(ENDPOINTS.contact, { 
                method: "post", 
                body: JSON.stringify(contactInfo),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            this.emailMessage = "Thanks for contacting us.";
        } catch(e) {
            this.emailMessage = "Service is currently down.\n Please try again later."
        }
    }

    uid4 = () => {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
      

}