import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';


class _Shop extends React.Component {
  componentDidMount = () => {
    if(window.scroll) {
      window.scroll({
        top: 100,
        left: 100,
        behavior: 'smooth'
      });
    }
  }
  
    render(){

    const { 
      categories, 
      storeItems, 
      imageItems, 
      topSellers,
      categoryItemCountMap, 
      addItemToCart, 
      selectedCategory, 
      selectCategory, 
      shopCartItems, 
      setViewDetails, 
      loading 
    } = this.props.BerryAwesomeStore;


        return (
            <>
  
  <section className="banner_area">
    <div className="container">
      <div className="banner_text">
        <h3>Shop</h3>
        <ul>
          <li><Link to="/home">Home</Link></li>
          <li><Link to="/shop">Shop</Link></li>
        </ul>
      </div>
    </div>
  </section>
  <section className="product_area p_100">
    <div className="container">

      <div className="row product_inner_row">
        <div className="col-lg-9">
      
          <div className="row product_item_inner">
           {
             loading ? (
              <div className="loading-container">
            <div className="lds-heart"><div></div></div>
            <p className="loading-title">Loading Deserts</p>
            </div>
             ) : null
           }
            {
              [...storeItems.values()].filter( item => {
                return  item?.item_data?.category_id === selectedCategory;
              }).map( item => {
                let itemPrice = item?.item_data?.variations[0].item_variation_data?.price_money?.amount;
                  if(itemPrice) {
                    itemPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat((itemPrice / 100).toFixed(2)));
                  }

                return <div className="col-lg-4 col-md-4 col-6">
              <div className="cake_feature_item">
                <div className="cake_img" onClick={() => {
                  this.props.history.push("/details", { product_id: item.id });
                  setViewDetails(item.id);
                }}>
                  <img src={imageItems.get(item?.image_id)?.image_data.url} alt="" />
                </div>
                <div className="cake_text">
                  <h4>{ itemPrice }</h4>
                  <h3>{ item?.item_data.name }</h3>
                  <button className="pest_btn" onClick={ _ => addItemToCart(item)} disabled={shopCartItems.has(item.id)}>
                      {
                        !shopCartItems.has(item.id) ? 'Add to cart' : 'Item In Cart'
                      }
                  </button>
                </div>
              </div>
            </div>
              })
            }
          </div>
          {/* <div className="product_pagination">
            <div className="left_btn">
              <a href="#"><i className="lnr lnr-arrow-left" /> New posts</a>
            </div>
            <div className="middle_list">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item"><a className="page-link" href="#">1</a></li>
                  <li className="page-item active"><a className="page-link" href="#">2</a></li>
                  <li className="page-item"><a className="page-link" href="#">3</a></li>
                  <li className="page-item"><a className="page-link" href="#">...</a></li>
                  <li className="page-item"><a className="page-link" href="#">12</a></li>
                </ul>
              </nav>
            </div>
            <div className="right_btn"><a href="#">Older posts <i className="lnr lnr-arrow-right" /></a></div>
          </div> */}
        </div>
        <div className="col-lg-3">
          <div className="product_left_sidebar">
            <aside className="left_sidebar p_catgories_widget">
              <div className="p_w_title">
                <h3>Product Categories</h3>
              </div>
              {
             loading ? (
              <div className="loading-container">
            <div className="lds-heart"><div></div></div>
            <p className="loading-title">Loading Categories</p>
            </div>
             ) : null
           }
              <ul className="list_style">
                {
                  [...categories.values()].map( c => {
                    return <li onClick={_ => selectCategory(c.id)} className={'category-list-item ' + (selectedCategory === c.id ? 'active': '')}> {c.category_data.name} ({ categoryItemCountMap.get(c.id) })</li>
                  })
                }
              
              </ul>
            </aside>
            {/* <aside className="left_sidebar p_price_widget">
              <div className="p_w_title">
                <h3>Filter By Price</h3>
              </div>
              <div className="filter_price">
                <div id="slider-range" />
                <label htmlFor="amount">Price range:</label>
                <input type="text" id="amount" readOnly />
                <a href="#">Filter</a>
              </div>
            </aside> */}
            <aside className="left_sidebar p_sale_widget">
              <div className="p_w_title">
                <h3>Top Sale Products</h3>
              </div>

              {
                topSellers.map( id => {
                  let item = storeItems.get(id);
                  let itemPrice = item?.item_data?.variations[0].item_variation_data?.price_money?.amount;
                  if(itemPrice) {
                    itemPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat((itemPrice / 100).toFixed(2)));
                  }
                  return (
                    <div className="media" onClick={() => {
                        this.props.history.push("/details", { product_id: item.id });
                        setViewDetails(item.id);
                      }}>
                      <div className="d-flex">
                        <img width="104" height="104" src={imageItems.get(item?.image_id)?.image_data.url} alt="" />
                      </div>
                      <div className="media-body">
                        <a>
                          <h4>{item?.item_data.name}</h4>
                        </a>
                        <h5>{ itemPrice }</h5>
                      </div>
                    </div>
                  )
                })
              }
            </aside>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

        )
    }
}

export const Shop = inject('BerryAwesomeStore')(observer(_Shop));