import React from 'react';


export const Footer = () => {
    return (
  <footer className="footer_area">
  <div className="footer_widgets">
    <div className="container">
      <div className="row footer_wd_inner">
        <div className="col-lg-3 col-6">
          <aside className="f_widget f_about_widget">
            <h3> <a>Berry Awesome</a></h3>
            <p>Our cakes are very moist, fluffy and absolutely delicious.</p>
            <ul className="nav">
            <li><a href="https://www.facebook.com/berryawesometreats" target={'_blank'}><i className="fa fa-facebook" /></a></li>
          <li><a href="https://www.instagram.com/berryawesometreats" target={'_blank'}><i className="fa fa-instagram" /></a></li>
            </ul>
          </aside>
        </div>
        <div className="col-lg-3 col-6">
        <aside className="f_widget f_link_widget">
            <div className="f_title">
              <h3>Work Times</h3>
            </div>
            <ul className="list_style">
              <li><a>Open Mon - Fri 24hrs</a></li>
              <li><a>Please allow us 48 hrs to respond</a></li>
            </ul>
          </aside>
        </div>
        <div className="col-lg-3 col-6">
        <aside className="f_widget f_contact_widget">
            <div className="f_title">
              <h3>Contact Info</h3>
            </div>
            {/* <h4>(1800) 574 9687</h4> */}
            <p>Dallas, TX</p>
            <h5>Email: Berryawesome2018@gmail.com</h5>
            <h5>Call Us: (469) 755 4848</h5>
          </aside>
        </div>
      </div>
    </div>
  </div>
  <div className="footer_copyright">
    <div className="container">
      <div className="copyright_inner">
        <div className="float-left">
          <h5>© Copyright Berry Awesome. All right reserved.</h5>
        </div>
      </div>
    </div>
  </div>
</footer>
    )
}