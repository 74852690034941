import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

class _Cart extends React.Component {
  componentDidMount = () => {
    if(window.scroll) {
      window.scroll({
        top: 100,
        left: 100,
        behavior: 'smooth'
      });
    }
  }
    render(){
      const { 
        cartInfo, 
        imageItems, 
        shopCartItems, 
        addItemToCart, 
        removeItemFromCart, 
        unitMap, 
        checkout
      } = this.props.BerryAwesomeStore;

        return (
            <>
  <section className="banner_area">
    <div className="container">
      <div className="banner_text">
        <h3>Cart</h3>
        <ul>
          <li><Link to="/home">Home</Link></li>
          <li><Link to="/cart">Cart</Link></li>
        </ul>
      </div>
    </div>
  </section>
  <section className="cart_table_area p_100">
    <div className="container">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Preview</th>
              <th scope="col">Product</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Total</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {
            [...shopCartItems.values()].map( item => {
              let itemPrice = item?.item_data?.variations[0].item_variation_data?.price_money?.amount;
              let lineItemTotal = 0; 
              let unitName = '';
              if(itemPrice) {
                  itemPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat((itemPrice / 100).toFixed(2)));
                  lineItemTotal =  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((item?.item_data?.variations[0].item_variation_data?.price_money?.amount / 100) * item.qty);
              }

              if(item?.item_data?.variations[0].item_variation_data?.measurement_unit_id) {
                unitName = unitMap.get(item?.item_data?.variations[0].item_variation_data?.measurement_unit_id).abbreviation;

                if(unitName.toLocaleLowerCase().includes("dozen") || unitName.includes("dz")) {
                  unitName = "Dozen"
                }
              }

              return <tr>
              <td>
                <img width="120" height="120" src={imageItems.get(item?.image_id)?.image_data.url} alt="" />
                </td>
                <td>{ item?.item_data.name }</td>
                <td>{ itemPrice }</td>
                <td>
                  <select className="product_select" value={item.qty} onChange={ e => {
                    console.log(parseInt(e.target.value ))
                    addItemToCart({...item, qty: parseInt(e.target.value )})
                  }}>
                    <option value={1}>1</option>
                    <option value={2}>2</option> 
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select> {unitName}
                </td>
                <td>{ lineItemTotal }</td>
                <td>
                <div onClick={_ => removeItemFromCart(item.id)} style={{padding: "padding: 4px 50px 0 0"}}>
                <i style={{ fontSize: 31 }} title="Remove Item" className="fa fa-close" aria-hidden="true" />
                </div>
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
        <div>
          { !shopCartItems.size ? <h2>Your cart is empty</h2>: null}
        </div>
      </div>

      <div className="row cart_total_inner">
        <div className="col-lg-7" />
        <div className="col-lg-5">
          <div className="cart_total_text">
            <div className="cart_head">
              Cart Total
            </div>
            <div className="sub_total">
              <h5>Sub Total <span> {
                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cartInfo.subTotal)
              }</span></h5>
            </div>
            <div className="sub_total">
              <h5>Delivery Fee<span> 
              {  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cartInfo.deliveryFee) }
              </span></h5>
            </div>
            <div className="sub_total">
              <h5>Sales Tax <span> {
                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cartInfo.salesTax)
              }</span></h5>
            </div>
            <div className="total">
              <h4>Total <span>{
                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cartInfo.total)
              }</span></h4>
            </div>
            <div className="cart_footer">
              <button className="pest_btn" onClick={checkout} disabled={!shopCartItems.size}>Proceed to Checkout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

        )
    }
}


export const Cart = inject('BerryAwesomeStore')(observer(_Cart));