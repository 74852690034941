import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

export const _Header = ({ BerryAwesomeStore : { shopCartItems }, title }) => {
    return (
    <>
{
  shopCartItems.size && title !== "Cart" ? (
    <Link to="/cart">
      <div className="cart-link">
            <div>
              Shop Cart
            </div>
            <div>
            <i className="lnr lnr-cart" /> <div className="cart-item-count">{shopCartItems.size}</div>
            </div>
      </div>
  </Link>
  ) : null
}
  <header className="main_header_area five_header">
  <div className="top_header_area row m0">
    <div className="container">
      <div className="float-left">
        <a href="tel:469-755-4848"><i className="fa fa-phone" aria-hidden="true" /> + (469) 755 4848</a>
        <a href="mailto:berryawesome2018@gmail.com?subject=Customer Service"><i className="fa fa-envelope-o" aria-hidden="true" />berryawesome2018@gmail.com</a>
      </div>
      <div className="float-right">
        <ul className="h_social list_style">
          <li><a href="https://www.facebook.com/berryawesometreats" target={'_blank'}><i className="fa fa-facebook" /></a></li>
          <li><a href="https://www.instagram.com/berryawesometreats" target={'_blank'}><i className="fa fa-instagram" /></a></li>
        </ul>
        <ul className="h_search list_style">
          <li className="shop_cart"><Link to="/cart"><i className="lnr lnr-cart" /></Link> <div className="cart-item-count">{shopCartItems.size}</div></li>
        </ul>
      </div>
    </div>
  </div>
  <div className="main_menu_two">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="/home">
          <img style={{ width: 150 }} src="/berrylogo.png" alt="Berry Awesome Logo"/>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="my_toggle_menu">
            <span />
            <span />
            <span />
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav justify-content-end">
            <li className={title === "Home" ? "active": ""}>
              <Link to="/home">Home </Link>
            </li>
            <li className={title === "About" ? "active": ""}><Link to="/about">About</Link></li>
            <li className={title === "Shop" ? "active": ""}><Link to="/shop">Shop</Link></li>
            <li className={title === "Contact Us" ? "active": ""}><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
</>
    )
}

export const Header = inject('BerryAwesomeStore')(observer(_Header))