import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

export class _Details extends React.Component {
  componentDidMount = () => {
    if(window.scroll) {
      window.scroll({
        top: 100,
        left: 100,
        behavior: 'smooth'
      });
    }
  }

    render(){
      const { viewItemDetailsId,storeItems, imageItems, shopCartItems, addItemToCart, unitMap } = this.props.BerryAwesomeStore;
      let item = storeItems.get(viewItemDetailsId);
      let itemPrice = item?.item_data?.variations[0].item_variation_data?.price_money?.amount;
      let unitName = null;

      if(item?.item_data?.variations[0].item_variation_data?.measurement_unit_id) {
        unitName = unitMap.get(item?.item_data?.variations[0].item_variation_data?.measurement_unit_id).abbreviation;
      }

      if(!viewItemDetailsId){
        return <Redirect to="/shop"/>
      }

      if(itemPrice) {
        itemPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat((itemPrice / 100).toFixed(2)));
      }

        return (
            <>
  <section className="banner_area">
    <div className="container">
      <div className="banner_text">
        <h3>Product Details</h3>
        <ul>
          <li><Link to="/home">Home</Link></li>
          <li><Link to="/details">Product Details</Link></li>
        </ul>
      </div>
    </div>
  </section>
  <section className="product_details_area p_100">
    <div className="container">
      <div className="row product_d_price">
        <div className="col-lg-6">
          <div className="product_img"><img className="img-fluid" src={imageItems.get(item?.image_id)?.image_data.url} alt=""/></div>
        </div>
        <div className="col-lg-6">
          <div className="product_details_text">
            <h4>{ item?.item_data.name }</h4>
            <p>{ item?.item_data.description }</p>
            <h5>Price : <span>
              { itemPrice } <small>Per {unitName}</small>
            </span></h5>
          
            {/* <div className="quantity_box">
              <label htmlFor="quantity">Quantity :</label>
              <input type="mumber" min="1" max="500" placeholder={1} id="quantity" />
            </div> */}
            <button className="pink_more" onClick={ _ => addItemToCart(item)} disabled={shopCartItems.has(item.id)}>
                      {
                        !shopCartItems.has(item.id) ? 'Add to cart' : 'Item In Cart'
                      }
            </button>
          </div>
        </div>
      </div>
      {/* <div className="product_tab_area">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Descripton</a>
            <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Specification</a>
            <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Review (0)</a>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
          </div>
          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
          </div>
          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
          </div>
        </div>
      </div> */}
    </div>
  </section>
</>

        )
    }
}


export const Details = inject('BerryAwesomeStore')(observer(_Details));