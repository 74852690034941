import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { Provider } from 'mobx-react';
import { BerryAwesomeStore } from './store';
import './index.css';
import {
  Layout,
  Cart,
  Shop,
  Details,
  Home,
  About,
  Contact,
  ThankYou
} from './pages'

const stores = {
  BerryAwesomeStore: new BerryAwesomeStore()
}

ReactDOM.render(
  <Provider {...stores}>
      <BrowserRouter>
        <Switch>
          <Layout
            exact
            path="/home"
            component={Home}
            user={null}
            title={'Home'}
          />
          <Layout
            exact
            path="/about"
            component={About}
            user={null}
            title={'About'}
          />

          <Layout
            exact
            path="/shop"
            component={Shop}
            user={null}
            title={'Shop'}
          />

          <Layout
            exact
            path="/cart"
            component={Cart}
            user={null}
            title={'Cart'}
          />
          <Layout
            exact
            path="/details"
            component={Details}
            user={null}
            title={'Details'}
          />

          <Layout
            exact
            path="/contact"
            component={Contact}
            user={null}
            title={'Contact Us'}
          />
            <Layout
            exact
            path="/thankyou"
            component={ThankYou}
            user={null}
            title={'Thank You'}
          />
          <Redirect to={"/home"}/>
          </Switch>
          </BrowserRouter>
          </Provider>,
  document.getElementById('root')
);
