import React from 'react';
import { inject, observer } from 'mobx-react';

const _Contact = ({ BerryAwesomeStore: { sendEmail, emailMessage  }}) => {
    if(window.scroll) {
      window.scroll({
        top: 100,
        left: 100,
        behavior: 'smooth'
      });
    }
    return (
        <section className="contact_form_area p_100">
  <div className="container">
    <div className="main_title">
      <h2>Get In Touch</h2>
      <h5>Do you have anything in your mind to let us know? Kindly don't delay to connect to us by means of our contact form.</h5>
    </div>
    <div className="row">
      <div className="col-lg-7">
        {
          emailMessage ? <> <h2>{emailMessage}</h2> <br/> </> : (
            <form className="row contact_us_form" onSubmit={(e) => {
              e.preventDefault();
              sendEmail(Object.fromEntries(new FormData(e.nativeEvent.target).entries()));
              e.target.reset();
            }}>
              <div className="form-group col-md-6">
                <input type="text" className="form-control" id="name" name="name" placeholder="Your name" maxLength="100" minLength="3" required />
              </div>
              <div className="form-group col-md-6">
                <input type="email" className="form-control" id="email" name="email" placeholder="Email address" required />
              </div>
              <div className="form-group col-md-12">
                <input type="text" className="form-control" id="subject" name="subject" maxLength="100" minLength="3" placeholder="Subject" required />
              </div>
              <div className="form-group col-md-12">
                <textarea className="form-control" name="message" id="message" rows={1} maxLength="1000" minLength="3" placeholder="Wrtie message" required defaultValue={""} />
              </div>
              <div className="form-group col-md-12">
                <button className="btn order_s_btn form-control" type="submit">submit now</button>
              </div>
            </form>
          )
        }
        
      </div>
      <div className="col-lg-4 offset-md-1">
        <div className="contact_details">
          <div className="contact_d_item">
            <h3>Address :</h3>
            <p>Dallas, TX</p>
          </div>
          <div className="contact_d_item">
            <h5>Phone : <a href="tel:469-755-4848">(469) 755 4848</a></h5>
            <h5>Email : <a href="mailto:berryawesome2018@gmail.com?subject=Customer Service">Berryawesome2018@gmail.com</a></h5>
          </div>
          <div className="contact_d_item">
            <h3>Opening Hours :</h3>
            <p>Open Mon - Fri 24hrs</p>
            <p>Please allow us 48 hrs to respond</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    )
}


export const Contact = inject('BerryAwesomeStore')(observer(_Contact));