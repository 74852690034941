import React from 'react';
import { SlideShow } from '../components';
import { Link } from 'react-router-dom';
import { Slide } from "react-slideshow-image";
import '../global.css';
import { inject, observer } from 'mobx-react';

class _Home extends React.Component {
    render(){
      const { 
        topSellers,
        storeItems, 
        imageItems, 
        addItemToCart, 
        shopCartItems, 
        setViewDetails, 
        loading 
      } = this.props.BerryAwesomeStore;
        return (
<>
<SlideShow/>
  {/*================Welcome Area =================*/}
  <section className="welcome_bakery_area">
    <div className="container">
      <div className="welcome_bakery_inner p_100">
        <div className="row">
          <div className="col-lg-6">
            <div className="main_title">
              <h2>We Make Tasty Treats</h2>
              <p>
              Everyone loves desserts! From cakes, sweets and fruits to wine and other “sweet things” a dessert can make the difference in a satisfying meal or a great time with friends and family. Even as we all try to stay fit and eat healthy, you can’t deny that a little bit of sweetness never hurt anyone.
              </p>
            </div>
            <div className="welcome_left_text">
              <p>
              According to science, kids need a healthy dose of sugar while growing to boost their cerebral abilities. We’re aware of the consequences of using artificial sweeteners and unhealthy ingredients. This is the reason why we use natural flavors and fruits. We also make our desserts in little servings.
              </p>
              <Link className="pink_btn" to="/about">Know more about us</Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="welcome_img">
              <img className="img-fluid" src="./homeImage.png" alt />
            </div>
          </div>
        </div>
      </div>
      <div className="cake_feature_inner">
        <div className="main_title">
          <h2>Our Top Sellers</h2>
          <p>
          We use fruits and flavors to add that natural sweetness to some of our desserts. We can provide desserts for a bake sale or a small gathering. Our goal is to ensure our customers are happy with the aesthetic appeal as well as the taste of their purchases.
          </p>
        </div>
        <div className="row">
        {
             loading ? (
              <div className="loading-container">
            <div className="lds-heart"><div></div></div>
            <p className="loading-title">Loading Deserts</p>
            </div>
             ) : null
           }
        {
          topSellers.map( id => {
              let item = storeItems.get(id);
              if(item) {
                let itemPrice = item?.item_data?.variations[0].item_variation_data?.price_money?.amount;
                  if(itemPrice) {
                    itemPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat((itemPrice / 100).toFixed(2)));
                  }
                  return (
                    <div className="col-lg-3 col-md-3 col-6">
                    <div className="item">
                    <div className="cake_feature_item">
                    <div className="cake_img" onClick={() => {
                        this.props.history.push("/details", { product_id: item.id });
                        setViewDetails(item.id);
                      }}>
                      <img src={imageItems.get(item?.image_id)?.image_data.url} alt="" />
                      </div>
                      <div className="cake_text">
                        <h4>{ itemPrice }</h4>
                        <h3>{ item?.item_data.name }</h3>
                        <button className="pest_btn" onClick={ _ => addItemToCart(item)} disabled={shopCartItems.has(item.id)}>
                          {
                            !shopCartItems.has(item.id) ? 'Add to cart' : 'Item In Cart'
                          }
                      </button>
                      </div>
                    </div>
                  </div>
                  </div>
                  )
              } else {
                return null;
              }
          })
        }
        </div>
      </div>
    </div>
  </section>
  {/*================End Welcome Area =================*/}
  {/*================Special Recipe Area =================*/}
  {/* <section className="special_recipe_area">
    <div className="container">
      <div className="special_recipe_slider owl-carousel">
        <div className="item">
          <div className="media">
            <div className="d-flex">
              <img src="img/recipe/recipe-1.png" alt />
            </div>
            <div className="media-body">
              <h4>Special Recipe</h4>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi equatur uis autem vel eum.</p>
              <a className="w_view_btn" href="#">View Details</a>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="media">
            <div className="d-flex">
              <img src="img/recipe/recipe-1.png" alt />
            </div>
            <div className="media-body">
              <h4>Special Recipe</h4>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi equatur uis autem vel eum.</p>
              <a className="w_view_btn" href="#">View Details</a>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="media">
            <div className="d-flex">
              <img src="img/recipe/recipe-1.png" alt />
            </div>
            <div className="media-body">
              <h4>Special Recipe</h4>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi equatur uis autem vel eum.</p>
              <a className="w_view_btn" href="#">View Details</a>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="media">
            <div className="d-flex">
              <img src="img/recipe/recipe-1.png" alt />
            </div>
            <div className="media-body">
              <h4>Special Recipe</h4>
              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi equatur uis autem vel eum.</p>
              <a className="w_view_btn" href="#">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/*================End Special Recipe Area =================*/}
  {/*================Service Area =================*/}
  {/* <section className="service_area">
    <div className="container">
      <div className="single_w_title">
        <h2>Among our offerings are the following</h2>
      </div>
      <div className="row service_inner">
        <div className="col-lg-4 col-6">
          <div className="service_item">
            <i className="flaticon-food-2" />
            <h4>Celebration Cakes</h4>
            <p>Duis aute irure dolor in reprehenderit in volup tate velit esse cillum dolore.</p>
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="service_item">
            <i className="flaticon-food-1" />
            <h4>Celebration Cakes</h4>
            <p>Duis aute irure dolor in reprehenderit in volup tate velit esse cillum dolore.</p>
          </div>
        </div>
        <div className="col-lg-4 col-6">
          <div className="service_item">
            <i className="flaticon-food" />
            <h4>Celebration Cakes</h4>
            <p>Duis aute irure dolor in reprehenderit in volup tate velit esse cillum dolore.</p>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/*================End Service Area =================*/}
  {/*================Client Says Area =================*/}
  <section className="client_says_area p_100">
    <div className="container">
      <div className="client_says_inner">
        <div className="c_says_title">
          <h2>What Our Client Says</h2>
        </div>
        <br/>
        <Slide autoplay={true} arrows={false} infinite={true}>
        <h3 className="testimony">
        "Somebody better come pick these up from me...  Brittany McGee with #berryawesome treats is trying to destroy all my workout plans lol Okay I tried one! That’s it!! I advise y’all not to order any if you don’t have the will power to not overdo it... Just let it go, keep scrolling... this ain’t for the weak... #LiquorChocolates"
        </h3>

        <h3 className="testimony">
        "It's the cleanliness, the customization, the affordability, the professionalism, and the taste for me😍 Not just for holidays, but for any and everyday. Place your orders with Brittany McGee  you'll be "berry" happy with your Berry Awesome treats‼️"
        </h3>
        <h3 className="testimony">
        "Why wait til tomorrow when you can love on them today...Enjoyed mother, daughter, and me time... Their surprise and smiles=priceless!! Thank you Brittany McGee (Berry Awsome) for outstanding service!! Yall go to her page and check her out."
        </h3>
        </Slide>
      
      </div>
    </div>
  </section>
  {/*================End Client Says Area =================*/}
  {/*================End Client Says Area =================*/}
  {/* <section className="our_chef_area p_100">
    <div className="container">
      <div className="row our_chef_inner">
        <div className="col-lg-3 col-6">
          <div className="chef_text_item">
            <div className="main_title">
              <h2>Our Chefs</h2>
              <p>Lorem ipsum dolor sit amet, cons ectetur elit. Vestibulum nec odios Suspe ndisse cursus mal suada faci lisis. Lorem ipsum dolor sit ametion.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="chef_item">
            <div className="chef_img">
              <img className="img-fluid" src="img/chef/chef-1.jpg" alt />
              <ul className="list_style">
                <li><a href="#"><i className="fa fa-twitter" /></a></li>
                <li><a href="#"><i className="fa fa-linkedin-square" /></a></li>
                <li><a href="#"><i className="fa fa-facebook-square" /></a></li>
                <li><a href="#"><i className="fa fa-skype" /></a></li>
              </ul>
            </div>
            <a href="#">
              <h4>Michale Joe</h4>
            </a>
            <h5>Expert in Cake Making</h5>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="chef_item">
            <div className="chef_img">
              <img className="img-fluid" src="img/chef/chef-2.jpg" alt />
              <ul className="list_style">
                <li><a href="#"><i className="fa fa-twitter" /></a></li>
                <li><a href="#"><i className="fa fa-linkedin-square" /></a></li>
                <li><a href="#"><i className="fa fa-facebook-square" /></a></li>
                <li><a href="#"><i className="fa fa-skype" /></a></li>
              </ul>
            </div>
            <a href="#">
              <h4>Michale Joe</h4>
            </a>
            <h5>Expert in Cake Making</h5>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="chef_item">
            <div className="chef_img">
              <img className="img-fluid" src="img/chef/chef-3.jpg" alt />
              <ul className="list_style">
                <li><a href="#"><i className="fa fa-twitter" /></a></li>
                <li><a href="#"><i className="fa fa-linkedin-square" /></a></li>
                <li><a href="#"><i className="fa fa-facebook-square" /></a></li>
                <li><a href="#"><i className="fa fa-skype" /></a></li>
              </ul>
            </div>
            <a href="#">
              <h4>Michale Joe</h4>
            </a>
            <h5>Expert in Cake Making</h5>
          </div>
        </div>
      </div>
    </div>
  </section> */}
</>

        )
    }
}


export const Home = inject('BerryAwesomeStore')(observer(_Home));