import React from 'react';
import { Link, Redirect } from 'react-router-dom';


export class ThankYou extends React.Component {

    render() {
        if(!window.location.search){
            return <Redirect to="/home"/>
        }

        return <section className="thankyouContainer">
            <h1>Thank You!</h1>
            <p>We'll contact you within 48 hrs to make arrangements for delivery.</p>
            <small className="text-muted">Need more information? <Link to="/contact">Contact Us</Link></small>
            <section className="buttonContainer">
                <a href={"https://www.facebook.com/berryawesometreats"} target="_blank"><button className="fb_button">Like us on facebook</button></a>
            </section>

        </section>
    }
}