import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Header } from '../components';
import { Footer } from '../components';



export const Layout = ({ component: Component, ...props }) => {


    return (
        <Route
            {...props}
            render={historyProps => {
                // function navActive(name){
                //     return historyProps.location.pathname.includes(name);
                // }
               return <>
                   <div>
                    <Header title={props.title}/>
                      <Component {...historyProps} />
                    <Footer/>
                  </div>
                </>
            }}
        />
    );
};

